<template>
  <div class="main-container">
    <h5 class="pageTitle" v-html="pageTitle"></h5>
    <mdb-container class="search-container">
      <mdb-row>
        <div class="ml-3">
          <mdb-select
              class="search-select"
              outline
              resetBtn
              v-model="selectOptions"
              placeholder="검색 항목"
              @getValue="getSelectValue"/>
        </div>
        <mdb-col>
          <mdb-input type="text" v-model="search" outline placeholder="검색어를 입력하세요."></mdb-input>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <mdb-container>
      <mdb-datatable-2
          v-model="data"
          class="text-center"
          striped
          bordered
          hover
          :searching="{value: search, field: fieldName} "
          @selected='onSelected($event)'
          noFoundMessage="데이터가 없습니다."/>
    </mdb-container>
  </div>
</template>

<script>
import {mdbCol, mdbContainer, mdbDatatable2, mdbInput, mdbRow, mdbSelect} from "mdbvue";
import user_list from "@/assets/data/columns/user_list";
import axios from "@/axios";

export default {
  name: "UserList",
  components: {
    mdbContainer,
    mdbDatatable2,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbSelect,
  },
  data() {
    return {
      token: this.$store.state.token.access_token,
      pageTitle: '회원 목록',
      data: {
        columns: user_list,
        rows: []
      },
      search: '',
      fieldName: '',
      selectOptions: [
        {text: "회원ID", value: "id"},
        {text: "이름", value: "name"},
        {text: "전화번호", value: "phone"},
      ],
      userType: 'approved'
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getUsers()
    },
    getUsers() {
      const self = this;
      self.data.rows.splice(0);
      let orderNo = 0;

      const data = {
        "status": self.userType,
      }

      const config = {
        method: 'get',
        url: '/users',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${self.token}`
        },
        params: data
      };

      axios(config)
          .then((querySnapshot) => {
            if (querySnapshot.data.size === 0) {
              return
            }
            querySnapshot.data.forEach((doc) => {
              orderNo++;
              doc['orderNo'] = orderNo;
              self.data.rows.push(doc)
            })
          })
    },
    onSelected(value) {
      const self = this;
      self.$router.push({name: 'userInfo', params: {id: value.uuid}})
    },
    getSelectValue(value) {
      const self = this;
      self.fieldName = value;
    },
  }
}
</script>

<style scoped>
.main-container {
  padding: 15px;
}

.pageTitle {
  font-weight: bold;
}

.search-select {
  width: 150px;
}
</style>
